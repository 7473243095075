import React, { useContext, useEffect, useState } from "react"
import { FavoritesProps } from "./Favorites.d"
import tw from "twin.macro"
import FavoritesDropDown from "./FavoritesDropdown"
import { Offer } from "../../../global"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { InventoryClient } from "../../../clients/InventoryClient"
import { Dealer } from "../Dealer"
import InventoryResultCard from "../../organisms/Inventory/InventoryResultCard"
import { DealerMap } from "../DealerMap"
import Lottie from "react-lottie-player"
import lottieJson from "../../../animations/14717-sedan-car-animation.json"
import { LanguageContext } from "../../../contexts/Language"
import { OfferCard } from "../OfferCard"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { AccessoryCard } from "../AccessoryCard"
import { Accessory } from "../../organisms/Accessories"
import FavoriteEmpty from "./FavoriteEmpty"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import AliceCarousel from "react-alice-carousel"
import { ContactContext } from "../../../contexts/Contact"
import { openFavoritesContactDealerModal } from "../../../contexts/Contact/actions"
import Share from "../../atoms/Icon/Share"
import { OffersClient } from "../../../clients/OffersClient"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary - Favorites menu. Lives in HeaderNavigation.
 *
 * @param {number} props.open - passed open state for desktop menus
 * @returns <FavoritesDropDown />
 *
 * @todo - Unit tests
 */

const Favorites: React.FC<FavoritesProps> = ({ open }): JSX.Element => {
  const [
    {
      favoritedVehicles,
      favoritedDealer,
      favoritedOffers,
      favoritedAccessories,
      favoriteMessage,
    },
  ] = useContext(FavoritesContext)
  const { language, _ } = useContext(LanguageContext)
  const [{}, dispatchContactForm] = useContext(ContactContext)
  const [{ activeDisclaimerPopoverId }] = useContext(DisclaimersContext)

  const [accessoriesData] = useState<Accessory[]>([])
  const [isLoading, setVehiclesLoading] = useState<boolean>(false)
  const [vehicleItems, setVehicleItems] = useState<JSX.Element[]>([])

  useEffect(() => {
    const updateVehicleData = async () => {
      setVehiclesLoading(true)
      const vehicleData = await InventoryClient.get({
        vin: favoritedVehicles.join(","),
        include: ["accessories"],
      })
      setVehicleItems(
        vehicleData && vehicleData?.vehicles?.length > 0
          ? vehicleData.vehicles.map((vehicle, i) => (
              <div css={[tw`mx-auto max-w-[590px]`]}>
                <InventoryResultCard
                  vehicle={vehicle}
                  css={[tw`bg-white`]}
                  key={`favorited-vehicle-${i}-${vehicle?.vin}`}
                  location={"favorite inventory"}
                  index={i}
                />
              </div>
            ))
          : [],
      )
      setVehiclesLoading(false)
    }

    if (
      Array.isArray(favoritedVehicles) &&
      favoritedVehicles?.length > 0 &&
      open
    ) {
      updateVehicleData()
    }
  }, [favoritedVehicles])

  const [offersItems, setOffersItems] = useState<JSX.Element[]>([])

  useEffect(() => {
    if (!favoritedOffers || !Array.isArray(favoritedOffers) || !open) return

    const getOffersData = async () => {
      try {
        const offersData = await OffersClient.getOfferById(favoritedOffers)
        if (offersData.offers) {
          if (offersData.offers?.length === 0) return
          setOffersItems(
            offersData.offers &&
              offersData.offers?.length > 0 &&
              offersData.offers?.map((offer: Offer, i: number) => (
                <div css={[tw`mx-4`]}>
                  <OfferCard
                    offer={offer}
                    css={[tw`!mx-auto`]}
                    cardType={offer?.cardLabel}
                    cardLabel={
                      language === "es"
                        ? offer?.displayLabelES
                        : offer?.displayLabel
                    }
                    key={`favorited-offer-${i}-${offer._id}`}
                  />
                </div>
              )),
          )
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    getOffersData()
  }, [favoritedOffers])

  // Custom ObservePoint ids for the Favourited Dealer component
  // Should be spread onto the <Dealers> component
  const favAnalyticsIds = {
    lmAnalyticsId: "learn more:top nav:favorite dealer",
    vdwAnalyticsId: "visit website:top nav:favorite dealer",
    ctcAnalyticsId: "call:top nav:favorite dealer",
    gdAnalyticsId: "directions:top nav:favorite dealer",
    ssrvcAnalyticsId: "schedule service:top nav:favorite dealer",
    cdAnalyticsId: "contact:top nav:favorite dealer",
  }

  const favoritedCategories = ["Dealer", "Inventory", "Offers", "Accessories"]
  const [favoritedTab, setFavoritedTab] = useState(
    favoriteMessage !== "default" ? favoriteMessage : "dealer",
  )

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  const redUnderlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const responsiveInventory = {
    0: { items: 1 },
    768: { items: 2 },
    1445: { items: 3 },
  }
  const responsiveOffers = {
    0: { items: 1 },
    767: { items: 2 },
    1024: { items: 3 },
    1300: { items: 4 },
  }
  const responsiveAccessories = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 },
  }

  const [accessoriesItems, setAccessoriesItems] = useState([])
  useEffect(() => {
    setAccessoriesItems(
      favoritedAccessories &&
        favoritedAccessories?.map((accessory: Accessory, i: number) => (
          <div
            key={`favorited-accessory-${i}`}
            css={[tw`mx-auto max-w-[400px]`]}
          >
            <div css={[tw`mx-4 mb-36 text-left`]}>
              <AccessoryCard
                modelsForAccy={[]}
                key={`favorited-accessory-${i}-${accessory._id}`}
                slug={accessory.seriesSlug}
                fromPanel={true}
                {...accessory}
                year={Number(accessory.year)} // Convert year to a number
                css={[tw`mx-6`]}
              />
            </div>
          </div>
        )),
    )
  }, [accessoriesData])

  const renderPrevButtonBottom = ({ isDisabled }: { isDisabled?: boolean }) => {
    return (
      <button
        css={[
          tw`absolute left-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`md:(-left-6 top-[45%])`,
          tw`lg:(-left-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
          activeDisclaimerPopoverId ? tw`hidden md:flex` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "left arrow",
          })
        }
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButtonBottom = ({ isDisabled }: { isDisabled?: boolean }) => {
    return (
      <button
        css={[
          tw`absolute right-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`md:(-right-6 top-[45%])`,
          tw`lg:(-right-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
          activeDisclaimerPopoverId ? tw`hidden md:flex` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "right arrow",
          })
        }
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div css={tw`overflow-hidden `}>
      <FavoritesDropDown show={open}>
        <section css={[tw`text-center w-full overflow-hidden`]}>
          <div
            css={[
              tw`mt-12 flex mb-6 justify-start items-end mx-6 overflow-x-scroll scrollbar-hide max-w-full`,
              tw`md:(my-6 flex justify-center mt-12)`,
              tw`xl:(mt-20)`,
            ]}
          >
            <span
              css={[
                tw`bg-gray-900 rounded text-gray-50 pb-1 px-3 inline-block mt-2 mr-2 whitespace-nowrap`,
              ]}
            >
              {_("My")} <Icon.Heart color="red-400" filled css={[tw`h-3`]} />{" "}
              {_("Favorites")}
            </span>
            {favoritedCategories?.map(cat => (
              <button
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "nav_click",
                    click_text: `top nav:Favorites:${cat}`,
                  })
                  setFavoritedTab(cat.toLowerCase())
                }}
                role="tab"
                css={[
                  tabAnimation,
                  focusAnimation,
                  tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2 capitalize`,
                  favoritedTab === cat.toLocaleLowerCase() && active,
                ]}
                analytics-id={`${cat}:top nav:favorites|`}
              >
                {_(cat)}
              </button>
            ))}
          </div>
          {favoritedTab === "dealer" && favoritedDealer && (
            <>
              <div
                css={[
                  tw`grid grid-cols-1 grid-rows-2 mb-10 mx-0`,
                  tw`md:(grid grid-cols-2 grid-rows-1 gap-6 max-w-7xl mb-10 !mx-8)`,
                  tw`lg:(!mx-auto)`,
                ]}
              >
                <div css={[tw`bg-white rounded-2xl text-left p-4 lg:(p-8)`]}>
                  <Dealer
                    css={[tw`col-span-1 row-start-2`]}
                    dealer={favoritedDealer}
                    collapsable={false}
                    {...favAnalyticsIds}
                  />
                </div>
                <div
                  css={[
                    tw`col-span-1 col-start-1 row-start-1 rounded-2xl border overflow-hidden`,
                    tw`md:(col-span-1 col-start-2 rounded-2xl border mb-0 overflow-hidden)`,
                  ]}
                >
                  <DealerMap
                    center={{
                      lat: favoritedDealer.Latitude,
                      lng: favoritedDealer.Longitude,
                    }}
                    dealers={[favoritedDealer]}
                    allowPan={false}
                  />
                </div>
              </div>
              <Link
                animated
                animatedThin
                to={"/dealers/"}
                css={[
                  tw`p-0 text-gray-900 leading-7 border border-transparent`,
                  tw`focus-visible:(border-black border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                ]}
              >
                {_("Change Dealer")}
              </Link>
            </>
          )}

          {favoritedTab === "dealer" && !favoritedDealer && (
            <FavoriteEmpty
              currentTab={favoritedTab}
              message={_(
                "Let's get you on the road! Select your local dealership to begin browsing inventory near you and finding your ideal car.",
              )}
              ctaLabel={_("Local Toyota Dealer")}
              ctaLink={language === "es" ? "/es/dealers/" : "/dealers/"}
            />
          )}

          {favoritedTab === "inventory" && favoritedVehicles.length > 0 && (
            <section
              aria-label="Favorited Vehicles Section"
              css={[tw`text-center w-full`]}
            >
              <>
                {isLoading && (
                  <div
                    css={[
                      tw`z-10 bg-opacity-70 flex items-center justify-center h-96`,
                    ]}
                  >
                    <div>
                      <span
                        css={[
                          tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                        ]}
                      >
                        {_(
                          "Hang tight, we're fetching your favorited vehicles.",
                        )}
                      </span>
                      <div>
                        <Lottie loop animationData={lottieJson} play />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  css={[
                    favoritedVehicles?.length === 0
                      ? tw`mb-[200px]`
                      : tw`mb-10 mx-auto justify-center`,
                  ]}
                >
                  <div
                    id="favorites-carousel"
                    css={[
                      tw`max-w-7xl my-10 relative mx-0`,
                      tw`md:(mx-8)`,
                      tw`lg:(mx-16 pb-32)`,
                      tw`xl:(mx-auto)`,
                    ]}
                  >
                    <AliceCarousel
                      mouseTracking={false}
                      touchTracking={false}
                      items={vehicleItems}
                      disableDotsControls
                      responsive={responsiveInventory}
                      paddingLeft={0}
                      paddingRight={0}
                      controlsStrategy="alternate"
                      renderPrevButton={renderPrevButtonBottom}
                      renderNextButton={renderNextButtonBottom}
                      activeIndex={activeIndex}
                      onSlideChanged={({ item }) => setActiveIndex(item)}
                    />
                    {!isLoading && (
                      <button
                        onClick={() =>
                          dispatchContactForm(openFavoritesContactDealerModal())
                        }
                        css={tw`flex items-center justify-center gap-2 mx-auto mt-4 md:mt-0`}
                      >
                        <Share css={tw`w-6`} />
                        <span
                          css={[tw`relative py-0.5`, redUnderlineAnimation]}
                        >
                          {_("Share Favorites")}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            </section>
          )}

          {favoritedTab === "inventory" && favoritedVehicles.length === 0 && (
            <FavoriteEmpty
              currentTab={favoritedTab}
              message={_(
                "It looks like you haven't added any vehicles to your favorites yet. Explore our collection and start curating your list today.",
              )}
              ctaLabel={_("Search Inventory")}
              ctaLink={language === "es" ? "/es/inventory/" : "/inventory/"}
            />
          )}

          {favoritedTab === "offers" && favoritedOffers.length > 0 && (
            <section
              aria-label="Favorited Offers Section"
              css={[tw`text-center mx-0`]}
            >
              {favoritedOffers && favoritedOffers?.length > 0 && (
                <div
                  css={[
                    //tw`px-8`,
                    tw`xl:(mx-16)`,
                  ]}
                >
                  <div
                    css={[
                      tw`max-w-7xl my-10 relative mx-6`,
                      tw`md:(mx-8)`,
                      tw`lg:(mx-16)`,
                      tw`xl:(mx-auto)`,
                    ]}
                  >
                    <AliceCarousel
                      mouseTracking
                      items={offersItems}
                      disableDotsControls
                      responsive={responsiveOffers}
                      paddingLeft={8}
                      paddingRight={8}
                      controlsStrategy="alternate"
                      renderPrevButton={renderPrevButtonBottom}
                      renderNextButton={renderNextButtonBottom}
                    />
                  </div>
                </div>
              )}
            </section>
          )}

          {favoritedTab === "offers" && favoritedOffers.length === 0 && (
            <FavoriteEmpty
              currentTab={favoritedTab}
              message={_(
                "No offers yet? Start browsing our offers and tap the heart icon to add items to your list!",
              )}
              ctaLabel={_("View Offers")}
              ctaLink={language === "es" ? "/es/offers/" : "/offers/"}
            />
          )}

          {favoritedTab === "accessories" &&
            favoritedAccessories.length > 0 && (
              <section
                aria-label="Favorited Accessories Section"
                css={[tw`text-center mx-0`]}
              >
                {favoritedAccessories && favoritedAccessories?.length > 0 && (
                  <div
                    css={[
                      favoritedAccessories?.length === 0
                        ? tw`mb-[200px]`
                        : tw`mb-10`,
                    ]}
                  >
                    <div
                      css={[
                        tw`my-10 relative mx-2 max-w-5xl`,
                        tw`md:(mx-10)`,
                        tw`lg:(mx-16)`,
                        tw`xl:(mx-auto)`,
                        tw`2xl:(max-w-7xl)`,
                      ]}
                    >
                      <AliceCarousel
                        mouseTracking
                        items={accessoriesItems}
                        disableDotsControls
                        responsive={responsiveAccessories}
                        paddingLeft={0}
                        paddingRight={0}
                        controlsStrategy="alternate"
                        renderPrevButton={renderPrevButtonBottom}
                        renderNextButton={renderNextButtonBottom}
                      />
                    </div>
                  </div>
                )}
              </section>
            )}

          {favoritedTab === "accessories" &&
            favoritedAccessories.length === 0 && (
              <FavoriteEmpty
                currentTab={favoritedTab}
                message={_(
                  "Your favorites list is currently empty. Don't worry - it's easy to get started! Explore our accessories and select the heart icon to add items you love.",
                )}
                ctaLabel={_("View Accessories")}
                ctaLink={
                  language === "es" ? "/es/accessories/" : "/accessories/"
                }
              />
            )}
        </section>
      </FavoritesDropDown>
    </div>
  )
}

export default Favorites
